import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboard.companies",
    path: `${APP_PREFIX_PATH}/companies`,
    component: React.lazy(() => import("views/app-views/dashboards/companies")),
  },
  {
    key: "dashboard.users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("views/app-views/dashboards/users")),
  },
  {
    key: "dashboard.licences",
    path: `${APP_PREFIX_PATH}/licences`,
    component: React.lazy(() => import("views/app-views/dashboards/licences")),
  },
  {
    key: "dashboard.projects",
    path: `${APP_PREFIX_PATH}/projects`,
    component: React.lazy(() => import("views/app-views/dashboards/projects")),
  },
];

import { createSlice } from "@reduxjs/toolkit";

function parseJwt (token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
}

const jwtToken = localStorage.getItem('auth_token') || null;
const userData = jwtToken && parseJwt(jwtToken);

const initialState = {
  userId: userData ? userData.UserId : '',
  companyId: userData ? userData.CompanyId : '',
  fullName: userData ? userData.FullName : '',
  email: userData ? userData.Email : '',
  role: userData ? userData.Role : '',
  permissions: userData ? userData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fillUserData: (state, action) => {
      state.userId = action.payload.UserId;
      state.companyId = action.payload.CompanyId;
      state.fullName = action.payload.FullName;
      state.email = action.payload.Email;
      state.role = action.payload.Role;
      state.permissions = action.payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    },
  }
});

export const {
  fillUserData,
} = userSlice.actions;

export default userSlice.reducer;